import { Stack, Grid } from '@mui/joy'
import { Logo } from '~/shared/ui/Logo'
import { PageContainer } from '~/shared/ui/PageContainer'
import { Card } from './Card'
import { useCards } from './hooks/useCards'

function HomePage() {
  const cards = useCards()

  return (
    <PageContainer title='Такси 369'>
      <Stack
        spacing={4}
        alignItems='center'
        justifyContent='center'
        sx={{ margin: '20px 120px' }}
      >
        <Logo />
        <Grid container spacing={2} sx={{ marginTop: '0 !important' }}>
          {cards.map((card) => (
            <Grid key={card.title} xs={3}>
              <Card {...card} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </PageContainer>
  )
}

export default HomePage
