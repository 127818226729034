import { Box } from '@mui/joy'

export function Logo() {
  return (
    <Box
      component='img'
      src='/logo.png'
      mx='auto'
      sx={{ marginBottom: '48px !important' }}
    />
  )
}
